<template>
  <div class="flex flex-col my-2">
    <div
      class="flex flex-col space-y-2 md:items-end md:flex-row justify-between"
    >
      <div class="py-1">
        <div class="text-xs" v-if="isArray">
          <ul class="flex" :class="`text-${color}-500`">
            <li v-for="(bread, index) in title" :key="`crumb-${index}`">
              <span v-if="index && index < title.length" class="mx-1">/</span>
              <router-link
                v-if="bread.link"
                class="text-blue-lighter"
                :to="bread.link"
                >{{ bread.crumb }}</router-link
              >
              <template v-else-if="bread.action">
                <a href="#" @click.prevent="$emit('reset')">
                  {{ bread.crumb }}
                </a>
              </template>
              <template v-else>
                {{ bread.crumb }}
              </template>
            </li>
          </ul>
        </div>
        <div v-else class="text-base font-medium">{{ title }}</div>
        <div class="w-full flex justify-between">
          <h1 class="text-base font-medium mt-2 -mb-2" v-if="pageTitle">
            {{ pageTitle }}
          </h1>
        </div>
      </div>
      <div class="space-x-4">
        <div
          class="buttons flex flex-col space-y-1 md:space-x-2 md:flex-row items-start md:items-center"
        >
          <router-link
            class="text-blue-500"
            v-if="
              upload &&
                upload.link &&
                permission &&
                can(
                  permission.module,
                  permission.subModule,
                  permission.childModule,
                  permission.operation,
                  permission.options
                )
            "
            :to="upload.link"
            >{{ upload.title }}</router-link
          >
          <a
            class=""
            v-else-if="upload && upload.event"
            href="#"
            @click.prevent="emitEvent(upload)"
            >{{ upload.title }}</a
          >

          <!-- Dropdown -->
          <div
            v-else-if="upload && upload.options"
            class="relative inline-block text-left self-end my-1 mr-2"
          >
            <span class="relative rounded z-10"
              ><a
                href="#"
                @click.prevent="show = !show"
                class="inline-flex justify-center w-full px-2 text-xs leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded hover:text-gray-500 focus:outline-none active:bg-gray-50 active:text-gray-800"
                type="button"
              >
                <span>{{ upload.title }} </span>
                <svg
                  class="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path></svg></a
            ></span>
            <div
              v-if="show"
              class="relative transition-all duration-300 transform origin-top-right -translate-y-2 scale-95 z-50 inset-0"
            >
              <div
                class="absolute right-0 w-36 mt-2 z-50 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded outline-none"
              >
                <!-- actions -->
                <a
                  href="#"
                  class="text-gray-700 flex w-full px-2 py-1 text-sm leading-5 text-left hover:bg-gray-200"
                  v-for="(option, index) in upload.options"
                  :key="`option-${index}`"
                  @click.prevent="emitAndClose(option)"
                >
                  <span>{{ option.title }}</span></a
                >
                <!-- actions -->
              </div>
            </div>
          </div>
          <!-- Dropdown -->

          <button
            v-if="
              action &&
                action.title &&
                permission &&
                can(
                  permission.module,
                  permission.subModule,
                  permission.childModule,
                  permission.operation,
                  permission.options
                )
            "
            @click="
              !action.disabled && action.link
                ? $router.push(link)
                : $bus.$emit('show-modal', {
                    show: true,
                    title: action.title,
                    component: action.component,
                    maxWidth: action.maxWidth,
                    parent_id: action.parentId,
                    event: action.event,
                    dialogType: action.dialogType,
                    type: action.type,
                    maxWidth: action.maxWidth,
                  })
            "
            class="px-2 py-2 text-xs rounded text-white"
            :class="
              action.disabled
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-green-dark'
            "
            :disabled="action.disabled"
          >
            {{ action.title }}
          </button>

          <button
            v-if="$route.name.includes('prepare') && print"
            @click.prevent="printMe"
            class="px-2 py-2 text-xs rounded text-white bg-green-dark"
          >
            Drucken
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: [
    'title',
    'pageTitle',
    'upload',
    'action',
    'color',
    'permission',
    'print',
  ],
  data() {
    return {
      show: false,
    }
  },
  methods: {
    emitEvent(event) {
      try {
        if (event.global) this.$bus.$emit(event.event)
        else this.$emit(event.event)
      } catch (e) {
        e
      }
    },
    emitAndClose(option) {
      if (option.global) this.$bus.$emit(option.event, { param: option.param })
      else this.$emit(option.event, { param: option.param })

      this.show = false
    },
    printMe() {
      this.updatePrinting(true)
      setTimeout(() => {
        this.$htmlToPaper('printMe')
        this.updatePrinting(false)
      }, 1000)
    },
  },
  computed: {
    isArray() {
      return Array.isArray(this.title)
    },
  },
}
</script>

<style></style>
