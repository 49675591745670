var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col my-2"},[_c('div',{staticClass:"flex flex-col space-y-2 md:items-end md:flex-row justify-between"},[_c('div',{staticClass:"py-1"},[(_vm.isArray)?_c('div',{staticClass:"text-xs"},[_c('ul',{staticClass:"flex",class:("text-" + _vm.color + "-500")},_vm._l((_vm.title),function(bread,index){return _c('li',{key:("crumb-" + index)},[(index && index < _vm.title.length)?_c('span',{staticClass:"mx-1"},[_vm._v("/")]):_vm._e(),(bread.link)?_c('router-link',{staticClass:"text-blue-lighter",attrs:{"to":bread.link}},[_vm._v(_vm._s(bread.crumb))]):(bread.action)?[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('reset')}}},[_vm._v(" "+_vm._s(bread.crumb)+" ")])]:[_vm._v(" "+_vm._s(bread.crumb)+" ")]],2)}),0)]):_c('div',{staticClass:"text-base font-medium"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"w-full flex justify-between"},[(_vm.pageTitle)?_c('h1',{staticClass:"text-base font-medium mt-2 -mb-2"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]):_vm._e()])]),_c('div',{staticClass:"space-x-4"},[_c('div',{staticClass:"buttons flex flex-col space-y-1 md:space-x-2 md:flex-row items-start md:items-center"},[(
            _vm.upload &&
              _vm.upload.link &&
              _vm.permission &&
              _vm.can(
                _vm.permission.module,
                _vm.permission.subModule,
                _vm.permission.childModule,
                _vm.permission.operation,
                _vm.permission.options
              )
          )?_c('router-link',{staticClass:"text-blue-500",attrs:{"to":_vm.upload.link}},[_vm._v(_vm._s(_vm.upload.title))]):(_vm.upload && _vm.upload.event)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.emitEvent(_vm.upload)}}},[_vm._v(_vm._s(_vm.upload.title))]):(_vm.upload && _vm.upload.options)?_c('div',{staticClass:"relative inline-block text-left self-end my-1 mr-2"},[_c('span',{staticClass:"relative rounded z-10"},[_c('a',{staticClass:"inline-flex justify-center w-full px-2 text-xs leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded hover:text-gray-500 focus:outline-none active:bg-gray-50 active:text-gray-800",attrs:{"href":"#","type":"button"},on:{"click":function($event){$event.preventDefault();_vm.show = !_vm.show}}},[_c('span',[_vm._v(_vm._s(_vm.upload.title)+" ")]),_c('svg',{staticClass:"w-5 h-5 ml-2 -mr-1",attrs:{"viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z","clip-rule":"evenodd"}})])])]),(_vm.show)?_c('div',{staticClass:"relative transition-all duration-300 transform origin-top-right -translate-y-2 scale-95 z-50 inset-0"},[_c('div',{staticClass:"absolute right-0 w-36 mt-2 z-50 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded outline-none"},_vm._l((_vm.upload.options),function(option,index){return _c('a',{key:("option-" + index),staticClass:"text-gray-700 flex w-full px-2 py-1 text-sm leading-5 text-left hover:bg-gray-200",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.emitAndClose(option)}}},[_c('span',[_vm._v(_vm._s(option.title))])])}),0)]):_vm._e()]):_vm._e(),(
            _vm.action &&
              _vm.action.title &&
              _vm.permission &&
              _vm.can(
                _vm.permission.module,
                _vm.permission.subModule,
                _vm.permission.childModule,
                _vm.permission.operation,
                _vm.permission.options
              )
          )?_c('button',{staticClass:"px-2 py-2 text-xs rounded text-white",class:_vm.action.disabled
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-green-dark',attrs:{"disabled":_vm.action.disabled},on:{"click":function($event){!_vm.action.disabled && _vm.action.link
              ? _vm.$router.push(_vm.link)
              : _vm.$bus.$emit('show-modal', {
                  show: true,
                  title: _vm.action.title,
                  component: _vm.action.component,
                  maxWidth: _vm.action.maxWidth,
                  parent_id: _vm.action.parentId,
                  event: _vm.action.event,
                  dialogType: _vm.action.dialogType,
                  type: _vm.action.type,
                  maxWidth: _vm.action.maxWidth,
                })}}},[_vm._v(" "+_vm._s(_vm.action.title)+" ")]):_vm._e(),(_vm.$route.name.includes('prepare') && _vm.print)?_c('button',{staticClass:"px-2 py-2 text-xs rounded text-white bg-green-dark",on:{"click":function($event){$event.preventDefault();return _vm.printMe.apply(null, arguments)}}},[_vm._v(" Drucken ")]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }